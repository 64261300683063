<template>
  <div class="q-pa-xs">
    <portal to="header">
      <terminal-header
          :title="$t('Move Items')"
          :out-focused="isFocused"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <q-carousel
        v-model="slide"
        transition-prev="scale"
        transition-next="scale"
        swipeable
        animated
        class="terminal-min-height"
    >
      <q-carousel-slide
          name="startPlaceItems"
          class="q-pa-none terminal-min-height"
      >
        <place-container
            :place="startPlace"
            :items="startPlaceItems"
        />
      </q-carousel-slide>

      <q-carousel-slide
          name="scan"
          class="q-pa-none terminal-min-height"
      >
        <jumbotron v-if="!startPlace"/>

        <div v-if="item">
          <dynamic-counter
              v-if="item.max > 0 || item.new > 0"
              :data="counter"
              @click="handleCounterSubmit"
          />

          <product-object
              :key="item.id"
              :data="item"
              change-detection
              detect-fix
          />
        </div>

        <place-object
            v-if="startPlace"
            :data="startPlace"
            :bg="endPoint === 'endPlace' ? 'bg-deep-orange-5' : 'bg-green-13'"
            :type="$t(startPlace.type[0].toUpperCase() + startPlace.type.slice(1)) + ' ' + $t('place')"
            class="q-mb-sm"
            @click="changeEndPoint('startPlace')"
        />

        <place-object
            v-if="endPlace"
            :data="endPlace"
            :bg="endPoint === 'startPlace' ? 'bg-deep-orange-5' : 'bg-green-13'"
            :type="$t(endPlace.type[0].toUpperCase() + endPlace.type.slice(1)) + ' ' + $t('place')"
            @click="changeEndPoint('endPlace')"
        />

        <picking-message :data="message"/>
      </q-carousel-slide>

      <q-carousel-slide
          name="endPlaceItems"
          class="q-pa-none terminal-min-height"
      >
        <place-container
            :place="endPlace"
            :items="endPlaceItems"
        />
      </q-carousel-slide>
    </q-carousel>

    <offers-modal ref="offersModal"/>

    <error-screen
        :message="error"
        @reset="handleErrorClose"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import OffersModal from '../components/modals/OffersModal.vue'

function data () {
  return {
    isFocused: false,
    slide: 'scan',
    item: null,
    startPlace: null,
    endPlace: null,
    startPlaceItems: [],
    startPlaceItemsLoaded: false,
    endPlaceItems: [],
    endPlaceItemsLoaded: false,
    endPoint: 'endPlace',
    endPoints: {
      end: 'endPlace',
      start: 'startPlace'
    },
    replaceByEndPoint: {
      endPlace: 'startPlace',
      startPlace: 'endPlace'
    },
    oppositeReplaceByEndPoint: {
      endPlace: 'endPlace',
      startPlace: 'startPlace'
    },
    lastBarcode: '',
    error: '',
    amount: 1,
    allocatedItems: []
  }
}

export default {
  name: 'MoveItemsNew',
  components: {
    OffersModal
  },
  data,
  computed: {
    ...mapGetters([
      'findStoragePlaceEvent'
    ]),
    message () {
      const data = {
        position: 'bottom'
      }

      if (this.startPlace && this.endPlace) {
        data.text = 'Scan location or product'
      } else if (!this.startPlace && !this.endPlace) {
        data.text = 'Scan location'
      } else {
        data.text = 'Scan location or allocate product'
      }

      return data
    },
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Reset'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.resetState()
          }
        }
      ]
    },
    counter () {
      const counterObj = {
        barcode: this.lastBarcode,
        max: 0,
        current: 0
      }

      if (this.item) {
        if (!this.endPlace) {
          counterObj.max = this.item.new + this.item.count
          counterObj.current = this.item.count
        } else {
          counterObj.max = this.item.max + this.item.count
          counterObj.current = this.item.count
        }
      }

      return counterObj
    }
  },
  watch: {
    slide (val) {
      this.item = null

      if (this[val] && !this[`${val}Loaded`]) {
        this.loadItems(val)
      }
    }
  },
  unmounted () {
    this.resetState()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    changeEndPoint (endPoint) {
      this.endPoint = endPoint
    },
    handleCounterSubmit (data) {
      const events = {
        quantityChange: () => {
          this.amount = data.data.quantity
        },
        focus: () => {
          this.isFocused = true
        },
        focusOut: () => {
          this.isFocused = false
        },
        counterSubmit: () => {
          this.amount = data.data.quantity
          this.handleBarcode({ value: data.data.barcode, raw: data.data.barcode })
        }
      }

      return events[data.event]()
    },
    handleErrorClose () {
      this.error = ''
    },
    loadItems (type) {
      const types = {
        startPlaceItems: {
          place: this.startPlace,
          load: this.loadStartPlaceItems
        },
        endPlaceItems: {
          place: this.endPlace,
          load: this.loadEndPlaceItems
        }
      }

      if (!types[type] || !types[type].place) {
        return Promise.resolve([])
      }

      return types[type].load()
    },
    loadStartPlaceItems () {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'place', value: this.startPlace.id }
        ],
        group: [
          { field: 'place', alias: 'i' },
          { field: 'productOffer', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query, this.startPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(data => {
            this.startPlaceItems = data.items
            this.startPlaceItemsLoaded = true
            return data
          })
    },
    loadEndPlaceItems () {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'place', value: this.endPlace.id }
        ],
        group: [
          { field: 'place' },
          { field: 'productOffer', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query, this.endPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(data => {
            this.endPlaceItems = data.items
            this.endPlaceItemsLoaded = true
            return data
          })
    },
    resetState () {
      if (this.startPlace) {
        this.$channels.user.publish('closePlace', this.startPlace.id)
      }

      if (this.endPlace) {
        this.$channels.user.publish('closePlace', this.endPlace.id)
      }

      Object.entries(data()).forEach(([key, value]) => {
        this[key] = value
      })
    },
    setPlace (place) {
      if (!this.startPlace) {
        if (this.endPlace && this.endPlace.type === 'employee' && place.type === 'employee') {
          return Promise.reject(new Error('You already scan employee place!'))
        }

        if (this.endPlace && this.endPlace.type !== 'employee' && place.type !== 'employee') {
          return Promise.reject(new Error('You must scan employee place!'))
        }

        this.startPlace = place
        this.startPlaceItems = []
        this.startPlaceItemsLoaded = false
      } else if (!this.endPlace) {
        if (this.startPlace && this.startPlace.type === 'employee' && place.type === 'employee') {
          return Promise.reject(new Error('You already scan employee place!'))
        }

        if (this.startPlace && this.startPlace.type !== 'employee' && place.type !== 'employee') {
          return Promise.reject(new Error('You must scan employee place!'))
        }

        this.endPlace = place
        this.endPlaceItems = []
        this.endPlaceItemsLoaded = false
      } else {
        if (
            this[this.oppositeReplaceByEndPoint[this.endPoint]].type === 'employee' &&
            place.type === 'employee'
        ) {
          return Promise.reject(new Error('You already scan empooyee place!'))
        }

        if (
            this[this.oppositeReplaceByEndPoint[this.endPoint]].type !== 'employee' &&
            place.type !== 'employee'
        ) {
          return Promise.reject(new Error('You must scan empooyee place!'))
        }

        this.$channels.user.publish('closePlace', this[this.replaceByEndPoint[this.endPoint]].id)
        this[this.replaceByEndPoint[this.endPoint]] = place
        this[`${this.replaceByEndPoint[this.endPoint]}Items`] = []
        this[`${this.replaceByEndPoint[this.endPoint]}ItemsLoaded`] = false
      }

      this.$channels.user.publish('openPlace', place.id, place)
      return Promise.resolve(place)
    },
    scanPlace (barcode) {
      if (this.findStoragePlaceEvent(barcode.value)) {
        return Promise.reject(new Error('Place is already used from someone!'))
      }

      return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            return this.setPlace(place)
          })
    },
    getItemByBarcode (arr, barcode) {
      const items = arr.filter(({ sku }) => sku === barcode.raw)

      if (items.length > 1) {
        return this.$refs.offersModal.show(items)
      }

      return Promise.resolve(items[0] || null)
    },
    async getAllocatedItem (barcode) {
      const item = await this.getItemByBarcode(this.allocatedItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'new' },
          { type: 'isnull', field: 'place' }
        ],
        group: [
          { field: 'place', alias: 'i' }
        ]
      }

      if (barcode.raw.startsWith('S/I/PO') && !barcode.raw.startsWith('S/I/POI')) {
        const lastIndex = barcode.raw.endsWith('*')
          ? barcode.raw.length - 1
          : barcode.raw.length

        let str = barcode.raw.slice(6, lastIndex)
        query.filter.push({ type: 'eq', field: 'productOffer', value: str })
      } else {
        query.filter.push({ type: 'eq', field: 'sku', value: barcode.raw })
      }

      return this.$service.storageItemEntity.getAll(query, this.startPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({ items }) => {
            if (items.length > 1) {
              return this.$refs.offersModal.show(items)
            }

            return items[0]
          })
          .then(item => {
            if (!item) {
              return Promise.reject(new Error(`Product with barcode ${barcode.raw} is not found or quantity is not enough!`))
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw,
              new: item.count
            }

            this.allocatedItems.push(updatedItem)

            return updatedItem
          })
    },
    getStartPlaceItems () {
      return Promise.resolve(this.startPlaceItemsLoaded)
          .then(isLoaded => {
            return isLoaded
                ? this.startPlaceItems
                : this.loadStartPlaceItems().then(({ items }) => items)
          })
    },
    getEndPlaceItems () {
      return Promise.resolve(this.endPlaceItemsLoaded)
          .then(isLoaded => {
            return isLoaded
                ? this.endPlaceItems
                : this.loadEndPlaceItems().then(({ items }) => items)
          })
    },
    getItem (point, barcode) {
      const points = {
        startPlace: () => {
          return this.getStartPlaceItems()
              .then(items => {
                return items.find(({ sku }) => sku === barcode.raw)
              })
        },
        endPlace: () => {
          return this.getEndPlaceItems()
              .then(items => {
                return items.find(({ sku }) => sku === barcode.raw)
              })
        }
      }

      if (!points[point]) {
        this.addErrorNotification(`Point ${point} is not recognized!`)
        return null
      }

      return points[point]()
    },
    allocateItem (barcode) {
      let newItem = null
      return this.getAllocatedItem(barcode)
          .then(item => {
            if (item.new < this.amount) {
              return Promise.reject(new Error(`Max quantity is ${item.new}`))
            }

            newItem = item

            return this.startPlaceItemsLoaded
                ? this.startPlaceItems
                : this.loadStartPlaceItems()
          })
          .then(() => {
            const query = {
              per_page: this.amount,
              filter: [
                { type: 'eq', field: 'sku', value: barcode.raw },
                { type: 'eq', field: 'state', value: 'new' },
                { type: 'isnull', field: 'place' },
                { type: 'eq', field: 'productOffer', value: newItem.id }
              ]
            }

            return this.$service.storageItemEntity.patch([{
              place: this.startPlace.id,
              state: 'normal'
            }], query, 'application/json+offers', this.startPlace.id)
          })
          .then(() => {
            return Promise.resolve(this.startPlaceItemsLoaded)
                .then(isLoaded => {
                  return isLoaded
                      ? this.startPlaceItems
                      : this.loadStartPlaceItems()
                })
                .then(() => {
                  let isExist = false

                  this.startPlaceItems = this.startPlaceItems.map(i => {
                    if (i.id === newItem.id) {
                      this.item = { ...i, count: i.count + this.amount, new: newItem.new - this.amount }
                      isExist = true
                      return this.item
                    }

                    return i
                  })

                  if (!isExist) {
                    this.item = {
                      ...newItem,
                      new: newItem.new - this.amount,
                      count: this.amount
                    }

                    this.startPlaceItems.push(this.item)
                  }

                  this.allocatedItems = this.allocatedItems.reduce((acc, i) => {
                    if (i.id !== newItem.id) {
                      acc.push(i)
                    } else {
                      const updatedItem = { ...i, new: newItem.new - this.amount }

                      if (updatedItem.new > 0) {
                        acc.push(updatedItem)
                      }
                    }

                    return acc
                  }, [])

                  return this.startPlaceItems
                })
          })
    },
    async loadItemFromStartPlace (barcode) {
      const item = await this.getItemByBarcode(this.startPlaceItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'place', value: this.startPlace.id }
        ],
        group: [
          { field: 'productOffer', alias: 'i' }
        ]
      }

      if (barcode.raw.startsWith('S/I/PO') && !barcode.raw.startsWith('S/I/POI')) {
        const lastIndex = barcode.raw.endsWith('*')
          ? barcode.raw.length - 1
          : barcode.raw.length

        let str = barcode.raw.slice(6, lastIndex)
        query.filter.push({ type: 'eq', field: 'productOffer', value: str })
      } else {
        query.filter.push({ type: 'eq', field: 'sku', value: barcode.raw })
      }

      return this.$service.storageItemEntity.getAll(query, this.startPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({ items }) => {
            const item = items[0]

            if (!item) {
              return null
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw
            }

            this.startPlaceItems.push(updatedItem)

            return updatedItem
          })
    },
    async loadItemFromEndPlace (barcode) {
      const item = await this.getItemByBarcode(this.startPlaceItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'place', value: this.endPlace.id }
        ],
        group: [
          { field: 'productOffer', alias: 'i' }
        ]
      }

      if (barcode.raw.startsWith('S/I/PO') && !barcode.raw.startsWith('S/I/POI')) {
        const lastIndex = barcode.raw.endsWith('*')
          ? barcode.raw.length - 1
          : barcode.raw.length

        let str = barcode.raw.slice(6, lastIndex)
        query.filter.push({ type: 'eq', field: 'productOffer', value: str })
      } else {
        query.filter.push({ type: 'eq', field: 'sku', value: barcode.raw })
      }

      return this.$service.storageItemEntity.getAll(query, this.endPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({ items }) => {
            const item = items[0]

            if (!item) {
              return null
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw
            }

            this.endPlaceItems.push(updatedItem)

            return updatedItem
          })
    },
    getItemForMove (barcode) {
      const gets = {
        startPlace: () => {
          return this.loadItemFromEndPlace(barcode)
              .then(item => {
                if (!item) {
                  return Promise.reject(new Error(`Product with barcode ${barcode.raw} is not found!`))
                }

                return Promise.all([item, this.loadItemFromStartPlace(barcode)])
              })
              .then(([item, secondItem]) => {
                if (secondItem) {
                  return {
                    ...secondItem,
                    max: item.count
                  }
                }

                return {
                  ...item,
                  max: item.count,
                  count: 0
                }
              })
        },
        endPlace: () => {
          return this.loadItemFromStartPlace(barcode)
              .then(item => {
                if (!item) {
                  return Promise.reject(new Error(`Product with barcode ${barcode.raw} is not found!`))
                }

                return Promise.all([item, this.loadItemFromEndPlace(barcode)])
              })
              .then(([item, secondItem]) => {
                if (secondItem) {
                  return {
                    ...secondItem,
                    max: item.count
                  }
                }

                return {
                  ...item,
                  max: item.count,
                  count: 0
                }
              })
        }
      }

      return gets[this.endPoint]()
    },
    moveItem (barcode) {
      return this.getItemForMove(barcode)
          .then(item => {
            if (!item) {
              return Promise.reject(new Error(`Product with barcode ${barcode.raw} is not found!`))
            }

            if (this.amount > item.max) {
              return Promise.reject(new Error(`Max count is ${item.max}!`))
            }

            const query = {
              per_page: this.amount,
              filter: [
                { type: 'eq', field: 'place', value: this[this.replaceByEndPoint[this.endPoint]].id }
              ]
            }

            if (barcode.raw.startsWith('S/I/PO') && !barcode.raw.startsWith('S/I/POI')) {
              const lastIndex = barcode.raw.endsWith('*')
                ? barcode.raw.length - 1
                : barcode.raw.length

              let str = barcode.raw.slice(6, lastIndex)
              query.filter.push({ type: 'eq', field: 'productOffer', value: str })
            } else {
              query.filter.push({ type: 'eq', field: 'sku', value: barcode.raw })
            }

            return Promise.all([item, this.$service.storageItemEntity.patch([{ place: this[this.endPoint].id }], query, 'application/json+offers', this[this.endPoint].id)])
          })
          .then(([item]) => {
            let isExist = false

            this[`${this.endPoint}Items`] = this[`${this.endPoint}Items`].reduce((acc, i) => {
              if (item.id === i.id) {
                isExist = true

                const updatedItem = {
                  ...i,
                  count: i.count + this.amount,
                  max: item.max - this.amount
                }

                this.item = updatedItem
                acc.push(updatedItem)
              } else {
                acc.push(i)
              }

              return acc
            }, [])

            if (!isExist) {
              this.item = { ...item, max: item.max - this.amount, count: this.amount }
              this[`${this.endPoint}Items`].push(this.item)
            }

            this[`${this.replaceByEndPoint[this.endPoint]}Items`] = this[`${this.replaceByEndPoint[this.endPoint]}Items`].reduce((acc, i) => {
              if (i.id === item.id) {
                const updatedItem = { ...i, count: i.count - this.amount }

                if (updatedItem.count > 0) {
                  acc.push(updatedItem)
                }
              } else {
                acc.push(i)
              }

              return acc
            }, [])

            this.amount = 1
          })
    },
    scanItem (barcode) {
      if (!this.endPlace) {
        return this.allocateItem(barcode)
      }

      return this.moveItem(barcode)
    },
    getPlaceError (barcode) {
      if (this.startPlace && `${this.startPlace.id}` === `${barcode.value}`) {
        return `Place with barcode ${barcode.raw} is already scanned`
      }

      if (this.endPlace && `${this.endPlace.id}` === `${barcode.value}`) {
        return `Place with barcode ${barcode.raw} is already scanned`
      }

      return ''
    },
    getItemError () {
      if (!this.startPlace && !this.endPlace) {
        return 'You must scan place!'
      }

      if (this.startPlace && !this.endPlace && this.startPlace.type === 'employee') {
        return 'You can\'t allocate products on employee place!'
      }

      return ''
    },
    getError (barcode) {
      return barcode.raw.includes('S/P/')
          ? this.getPlaceError(barcode)
          : this.getItemError(barcode)
    },
    handleBarcode (barcode) {
      const error = this.getError(barcode)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      this.slide = 'scan'
      this.lastBarcode = barcode.raw

      return Promise.resolve(barcode.raw.includes('S/P/'))
          .then(isPlace => {
            return isPlace
                ? this.scanPlace(barcode)
                : this.scanItem(barcode)
          })
          .catch(error => {
            this.error = error.message
          })
    }
  }
}
</script>
